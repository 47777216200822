// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quick-links-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  padding: 32px 64px;
  background-color: var(--ion-color-light);
}
@media screen and (max-width: 768px) {
  .quick-links-container {
    padding: 32px 20px;
  }
}

.section {
  flex: 1 1 22%;
  min-width: 240px;
  display: flex;
  flex-direction: column;
}

.link-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.link-item {
  text-decoration: none;
  font-size: 14px;
  color: inherit;
  text-decoration: none;
}
.link-item:hover {
  text-decoration: underline;
}

.quick-links-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}

@media (max-width: 1200px) {
  .section {
    flex: 1 1 48%;
  }
}
@media (max-width: 800px) {
  .section {
    flex: 1 1 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/quicklinks/quick-links.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,SAAA;EACA,kBAAA;EACA,wCAAA;AACF;AAAE;EANF;IAOI,kBAAA;EAGF;AACF;;AAAA;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AAGF;;AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAGF;;AAAA;EACE,qBAAA;EACA,eAAA;EACA,cAAA;EACA,qBAAA;AAGF;AAFE;EACE,0BAAA;AAIJ;;AAAA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAGF;;AAAA;EACE;IACE,aAAA;EAGF;AACF;AAAA;EACE;IACE,cAAA;EAEF;AACF","sourcesContent":[".quick-links-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 32px;\n  padding: 32px 64px;\n  background-color: var(--ion-color-light);\n  @media screen and (max-width: 768px) {\n    padding: 32px 20px;\n  }\n}\n\n.section {\n  flex: 1 1 22%;\n  min-width: 240px;\n  display: flex;\n  flex-direction: column;\n}\n\n.link-container {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.link-item {\n  text-decoration: none;\n  font-size: 14px;\n  color: inherit;\n  text-decoration: none;\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n.quick-links-title {\n  font-size: 20px;\n  font-weight: 500;\n  margin-bottom: 12px;\n}\n\n@media (max-width: 1200px) {\n  .section {\n    flex: 1 1 48%;\n  }\n}\n\n@media (max-width: 800px) {\n  .section {\n    flex: 1 1 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
