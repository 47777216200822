import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-web-footer',
  templateUrl: './web-footer.component.html',
  styleUrls: ['./web-footer.component.scss'],
})
export class WebFooterComponent implements OnInit {

  constructor(
    private _router: Router,
  ) { }

  ngOnInit() {}

  navigateToPrivacy() {
    this._router.navigate(['privacy']);
  }
  naviagetToTerms() {
    this._router.navigate(['terms']);
  }

}
