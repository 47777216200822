import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StoreService } from '../store/store.service';

import { register } from 'swiper/element/bundle';
import { StoreCollectionsService } from '../core/services/store-collections.service';
import { debounceTime, Observable } from 'rxjs';
import { StoreCollection, Vendor } from '../core/model/store-collection.model';
import { environment } from 'src/environments/environment.prod';
import { BlogService } from '../core/services/blog.service';
import {
  Article,
  ArticleResponse,
  ManipulatedArticle,
} from '../core/model/blog.model';
import { ModalController } from '@ionic/angular';
import { StoreListService } from '../core/services/store.service';
import { UiService } from '../ui.service';
import { Stores } from '../core/model/all-store.modal';
import { WebBanner } from '../core/model/banners.model';
import Swiper from 'swiper';

import { Navigation, Pagination } from 'swiper/modules';

// Register the required modules
Swiper.use([Navigation, Pagination]);

register();

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  showMenu = false;
  showResults = false;
  baseUrl = environment.base_url;
  strapiImageBaseUrl = environment.strapiImageBaseUrl;
  StoreCollections: StoreCollection[] = [];
  fetchStoreCollection$: Observable<any>;
  article: ManipulatedArticle = {} as ManipulatedArticle;
  fetchBanner$: Observable<any>;
  banners: WebBanner[] = [];

  collectionSwiperConfig = {
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
      clickable: true,
    },
  };
  blogSwiperConfig = {
    slidesPerView: 2,
    spaceBetween: 16,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
    },
  };
  constructor(
    private _router: Router,
    private title: Title,
    private _storeService: StoreService,
    private metaTagService: Meta,
    public _storeCollectionService: StoreCollectionsService,
    public _blogService: BlogService,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    this.setTitleAndTags();
    this.fetchStoreCollection();
    this.onFetchBlog();
    this.fetchBanner();

    return;
    if (window.location.href.split('.').length > 1) {
      // sub domain
      this.setTitleAndTags();
    } else {
      this._router.navigateByUrl('/welcome-screen');
    }
  }

  onFetchBlog() {
    this._blogService
      .fetchBlogData({ populate: '*' })
      .then((res) => {
        this.article = this.groupByCategory(res.data);
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
      });
  }
  fetchBanner() {
    this.fetchBanner$ = this._storeCollectionService.fetchBanner({
      location: 'HOME_HEADER',
    });

    this.fetchBanner$.subscribe((res) => {
      if (res) {
        this.banners = res.data;
      }
    });
  }
  groupByCategory(articles: Article[]): {
    [key: string]: { description: string; articles: Article[] };
  } {
    return articles.reduce((acc, article) => {
      const categoryName = article?.category?.name || 'Uncategorized';
      const categoryDescription = article?.category?.description || null;

      if (!acc[categoryName]) {
        acc[categoryName] = { description: categoryDescription, articles: [] };
      }
      acc[categoryName].articles.push(article);
      return acc;
    }, {} as { [key: string]: { description: string; articles: Article[] } });
  }

  async onDetailsPage(data: Article) {
    this._router.navigate(['/blog/details', data.slug], {
      replaceUrl: false,
    });
  }

  async onStoreDetailsPage(data: Vendor) {
    // this._router.navigate(['/store/details'], {
    //   queryParams: {
    //     storeName: data.vendorStoreName,
    //     code: data.vendorCode,
    //     // code: 'EI101',
    //     VendorId: data.VendorIdPK,
    //     tableNumber: 0,
    //     diningAreaId: 0,
    //   },
    // });
    const storeName = data.vendorStoreName.replace(/\s+/g, '-');
    const url = `/${storeName}/${data.vendorCode}`;
    this._router.navigateByUrl(url);
  }

  fetchStoreCollection() {
    this.fetchStoreCollection$ = this._storeCollectionService.fetchCollection();

    this.fetchStoreCollection$.subscribe((res) => {
      if (res) {
        this.StoreCollections = res.data;
      }
    });
  }
  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  setTitleAndTags() {
    this._storeService.fetchVendorMetaTags().subscribe((res) => {
      console.log(res);
      this.title.setTitle(res.data[0].title);
      this.metaTagService.addTag({
        content: res.data[0].title,
        property: 'og:title',
        name: 'og_title',
      });
    });
  }
  onImageLoadError(e: any) {
    e.target.src = '../../assets/images/dummy/1.png';
  }
  onBlogImageError(e: any) {
    e.target.src = '../../assets/images/Easeinn-Blog.png';
  }
  navigateToOnboarding() {
    this._router.navigate(['/vendor-onboarding']);
  }
}
