import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AllStoresPage } from './all-stores/all-stores.page';
import { AuthPage } from './auth/auth.page';
import { WebLoginPage } from './auth/web-login/web-login.page';
import { AskCustomerInfoPage } from './cart/ask-customer-info/ask-customer-info.page';
import { CartPage } from './cart/cart.page';
import { CouponsPage } from './coupons/coupons.page';
import { InvoicePage } from './invoice/invoice.page';
import { LandingPage } from './landing/landing.page';
import { NoInternetPage } from './no-internet/no-internet.page';
import { NotificationsPage } from './notifications/notifications.page';
import { OffersPage } from './offers/offers.page';
import { OopsPage } from './oops/oops.page';
import { OrderStatusPage } from './order/order-status/order-status.page';
import { OrderPage } from './order/order.page';
import { ProfilePage } from './profile/profile.page';
import { SessionGuard } from './session.guard';
import { PrivacyComponent } from './static-pages/privacy/privacy.component';
import { TermsAndConditionsComponent } from './static-pages/terms-and-conditions/terms-and-conditions.component';
import { StorePage } from './store/store.page';
import { UserAccountPage } from './user-account/user-account.page';
import { UserAddressesPage } from './user-addresses/user-addresses.page';
import { WelcomeScreenPage } from './welcome-screen/welcome-screen.page';
import { DeleteAccountPage } from './delete-account/delete-account.page';
import { HomePage } from './home/home.page';
import { BlogListingPage } from './blog-listing/blog-listing.page';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { StoreDetailsComponent } from './store-details/store-details.component';
import { PlatformCheckGuard } from './core/services/platform.guard';
import { VendorOnboardingPage } from './vendor-onboarding/vendor-onboarding.page';

const routes: Routes = [
  {
    path: '',
    canActivate: [PlatformCheckGuard],
    children: [],
  },
  { path: '', redirectTo: 'welcome-screen', pathMatch: 'full' },

  { path: 'home', component: HomePage },
  { path: 'menu/:id', component: AuthPage },
  { path: 'cart', component: CartPage },
  { path: 'store', component: StorePage },
  { path: 'order', component: OrderPage },
  { path: 'oops', component: OopsPage, pathMatch: 'full' },
  {
    path: 'order-status',
    component: OrderStatusPage,
  },
  { path: 'offers', component: OffersPage },
  {
    path: 'welcome-screen',
    component: WelcomeScreenPage,
    canActivate: [SessionGuard],
  },
  { path: 'auth', component: AuthPage },
  // {
  //   path: 'all-stores',
  //   component: AllStoresPage,
  // },
  {
    path: 'stores',
    component: AllStoresPage,
  },
  {
    path: 'stores/:areaName-:cityName/:id',
    component: AllStoresPage,
    // canActivate: [AreaCheckGuard]
  },
  // {
  //   path: 'stores/:cityName/:cityId',
  //   component: AllStoresPage,
  // },
  {
    path: 'stores/:storeCategoryName-:cityName/:cityId/:storeCategoryId',
    component: AllStoresPage,
  }, // functional above route
  {
    path: 'landing',
    component: LandingPage,
  },
  {
    path: 'no-internet',
    component: NoInternetPage,
  },
  {
    path: 'user-account',
    component: UserAccountPage,
  },
  {
    path: 'profile',
    component: ProfilePage,
  },
  {
    path: 'notifications',
    component: NotificationsPage,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'terms',
    component: TermsAndConditionsComponent,
  },
  {
    path: 'invoice/:id',
    component: InvoicePage,
  },
  {
    path: 'coupons',
    component: CouponsPage,
  },
  {
    path: 'auth/web-login',
    component: WebLoginPage,
  },
  {
    path: 'cart/ask-customer-info',
    component: AskCustomerInfoPage,
  },
  {
    path: 'user-addresses',
    component: UserAddressesPage,
  },
  {
    path: 'delete-account',
    component: DeleteAccountPage,
  },
  {
    path: 'blog-listing',
    component: BlogListingPage,
  },
  {
    path: 'blogs',
    component: BlogListingPage,
  },
  {
    path: 'blog/details/:slug',
    component: BlogDetailsComponent,
  },
  {
    path: 'blog/:slug',
    component: BlogDetailsComponent,
  },
  // {
  //   path: 'store/details/:storeName/:storeCode',
  //   component: StoreDetailsComponent,
  // },
  {
    path: 'vendor-onboarding',
    component: VendorOnboardingPage,
  },
  {
    path: ':storeName/:storeCode',
    component: StoreDetailsComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
