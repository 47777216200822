// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Remove Invalid Field Styling */
/* Removed red border and background for invalid fields */
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-light);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 64px;
  min-height: 400px;
  background: url('Easeinn-Vendor-Banner-01.png');
}
@media screen and (max-width: 768px) {
  .hero {
    padding: 20px;
    min-height: 240px;
  }
}
.hero__header {
  text-align: center;
}
.hero__header--title {
  font-size: 48px;
  font-weight: 700;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .hero__header--title {
    font-size: 36px;
    margin-top: 32px;
  }
}
.hero__header--subtitle {
  font-size: 20px;
  color: #fafafa;
  margin-top: 16px;
}
@media screen and (max-width: 768px) {
  .hero__header--subtitle {
    font-size: 16px;
  }
}
.hero__star {
  font-size: large;
  color: red;
}

ion-grid {
  max-width: 660px;
}
ion-grid h3 {
  border-bottom: 1px solid var(--ion-color-medium);
  padding-bottom: 8px;
}
ion-grid ion-button {
  max-width: 320px;
  margin-inline: auto;
}
@media (max-width: 768px) {
  ion-grid ion-button {
    max-width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/vendor-onboarding/vendor-onboarding.page.scss"],"names":[],"mappings":"AAAA,iCAAA;AACA,yDAAA;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,wCAAA;EACA,kCAAA;EACA,sBAAA;EACA,4BAAA;EACA,aAAA;EACA,iBAAA;EACA,+CAAA;AAAF;AACE;EAZF;IAaI,aAAA;IACA,iBAAA;EAEF;AACF;AADE;EACE,kBAAA;AAGJ;AAFI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAIN;AAHM;EAJF;IAKI,eAAA;IACA,gBAAA;EAMN;AACF;AAJI;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AAMN;AALM;EAJF;IAKI,eAAA;EAQN;AACF;AALE;EACE,gBAAA;EACA,UAAA;AAOJ;;AAHA;EACE,gBAAA;AAMF;AAJE;EACE,gDAAA;EACA,mBAAA;AAMJ;AAHE;EACE,gBAAA;EACA,mBAAA;AAKJ;AAJI;EAHF;IAII,eAAA;EAOJ;AACF","sourcesContent":["/* Remove Invalid Field Styling */\n/* Removed red border and background for invalid fields */\n\n.hero {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--ion-color-light);\n  background-position: center center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  padding: 64px;\n  min-height: 400px;\n  background: url(../../assets/images/Easeinn-Vendor-Banner-01.png);\n  @media screen and (max-width: 768px) {\n    padding: 20px;\n    min-height: 240px;\n  }\n  &__header {\n    text-align: center;\n    &--title {\n      font-size: 48px;\n      font-weight: 700;\n      color: #ffffff;\n      @media screen and (max-width: 768px) {\n        font-size: 36px;\n        margin-top: 32px;\n      }\n    }\n    &--subtitle {\n      font-size: 20px;\n      color: #fafafa;\n      margin-top: 16px;\n      @media screen and (max-width: 768px) {\n        font-size: 16px;\n      }\n    }\n  }\n  &__star{\n    font-size: large;\n    color: red;\n  }\n}\n\nion-grid {\n  max-width: 660px;\n\n  h3 {\n    border-bottom: 1px solid var(--ion-color-medium);\n    padding-bottom: 8px;\n  }\n\n  ion-button {\n    max-width: 320px;\n    margin-inline: auto;\n    @media (max-width: 768px) {\n      max-width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
