import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { NgForm } from "@angular/forms";
import { environment } from "src/environments/environment.prod";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class VendorServices {
  
  constructor(private http: HttpClient) {}

  fetchCity(stateId?: number): Observable<any> {
    let params = new HttpParams();
    if (stateId) {
      params = params.append("StateIdFK", stateId.toString());
    }
    return this.http.get(environment.base_url + environment.fetch_city_method, {
      params,
    });
  }

  fetchArea(cityId?: number): Observable<any> {
    let params = new HttpParams();
    if (cityId) {
      params = params.append("CityIdFK", cityId.toString());
    }
    return this.http.get(environment.base_url + environment.fetch_area_method, {
      params,
    });
  }
  
  fetchStates(): Observable<any> {
    return this.http.get(
      environment.base_url + environment.fetch_states_method
    );
  }

  createVendor(vendorForm: FormData) {
    return this.http.post(
      environment.base_url + environment.vendor_onboarding,
      vendorForm,
      {
        observe: "response",
      }
    );
  }

  fetchCategory(): Observable<any> {
    return this.http.get(
      environment.base_url + environment.fetch_store_category_method,
    );
  }
}
