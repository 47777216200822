import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { StoreListService } from '../core/services/store.service';
import { UiService } from '../ui.service';
import { Stores } from '../core/model/all-store.modal';
import { environment } from 'src/environments/environment.prod';
import { Vendor } from '../core/model/store-collection.model';
import { StoreCategory } from '../core/model/store-category.model';

@Component({
  selector: 'app-all-stores',
  templateUrl: './all-stores.page.html',
  styleUrls: ['./all-stores.page.scss'],
})
export class AllStoresPage implements OnInit {
  allStoresData: Stores[] = [];
  showSearchBar = false;
  baseUrl = environment.base_url;
  city: string;
  state: string;
  area: string;
  storeCategoryName: string;
  storeCategoryId: any;
  areaId: any;
  cityId: number;
  isFocusMode: boolean = false;
  filter!: string;
  storeCategory: StoreCategory[] = [];
  selectedCategoryId: number | string = null;
  id: any;
  constructor(
    private _router: Router,
    public modalController: ModalController,
    private _store: StoreListService,
    private _uiService: UiService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._route.queryParams.subscribe((queryParams) => {
      if (queryParams['filter']) {
        this.filter = queryParams['filter']; // Store the filter for your component
      }
      this._route.params.subscribe((params) => {
        if (this.filter === 'BY_AREA') {
          const AreaAndCityName = params['areaName-:cityName'];
          if (AreaAndCityName) {
            let items = AreaAndCityName.split('-');
            if (items[0] !== 'null') {
              this.area = items[0];
            }
            if (items[1] !== 'null') {
              this.city = items[1];
            }
          }
          if (params['id']) {
            this.areaId = params['id'];
          }
        }
        if (this.filter === 'BY_CITY') {
          this.city = params['areaName-:cityName'];

          if (params['id']) {
            this.cityId = params['id'];
          }
        }
        if (this.filter === 'BY_STORE_CITY') {
          const storeCategoryNameAndCityName =
            params['storeCategoryName-:cityName'];
          let items = storeCategoryNameAndCityName.split('-');
          if (items[0] !== 'null') {
            this.storeCategoryName = items[0];
          }
          if (items[1] !== 'null') {
            this.city = items[1];
          }
          if (params['cityId']) {
            this.cityId = params['cityId'];
          }
          if (params['storeCategoryId']) {
            this.storeCategoryId = params['storeCategoryId'];
          }
        }

        this.onGet();
      });
    });

    this.onGet();
    this.fetchStoreCategory();
  }
  fetchStoreCategory() {
    this._store.fetch().subscribe({
      next: (res) => {
        this.storeCategory = res.data;
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }
  onSelectCategory(id: number | string) {
    if (id === 'ALL') {
      this.selectedCategoryId = null;
    } else {
      this.selectedCategoryId = id;
    }
    this.onGet();
  }
  onFocusLocation() {
    this.isFocusMode = true;
    setTimeout(() => {
      this.isFocusMode = false;
    }, 2000);
  }
  openStore() {
    // this._router.navigateByUrl('/store');
  }

  toggleSearchBar(e: any) {
    this.showSearchBar = !this.showSearchBar;
  }
  onImageLoadError(e: any) {
    e.target.src = '../../assets/icons/store.svg';
  }
  onGet() {
    this._store
      .fetchStore({
        vendorStatus: 'ACTIVE',
        currentPage: 1,
        itemsPerPage: 100,
        searchData: '',
        expirationDateStart: '',
        expirationDateEnd: '',
        ...(this.selectedCategoryId && {
          StoreCategoryIdFK: this.selectedCategoryId,
        }),
        ...(this.areaId && { AreaIdFK: this.areaId }),
        ...(this.cityId && !this.areaId && { CityIdFK: this.cityId }),
      })
      .subscribe({
        next: (res) => {
          this.allStoresData = res.data;
        },
        error: (err) => {
          this._uiService.presentToast(err);
        },
      });
  }

  async openScanner() {
    // const modal = await this.modalController.create({
    //   component: ScannerComponent,
    //   cssClass: "scanner-modal",
    // });
    // return await modal.present();
  }
  async onStoreDetailsPage(data: Stores) {
    const storeName = data.vendorStoreName.replace(/\s+/g, '-');
    const url = `/${storeName}/${data.vendorCode}`;
    this._router.navigateByUrl(url);
  }
}
