import { Component, OnInit } from '@angular/core';
import { InstaPostServices } from 'src/app/core/services/insta-posts';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { InstaPost } from '../../../core/model/insta-post.modal';

@Component({
  selector: 'app-insta-posts',
  templateUrl: './insta-posts.component.html',
  styleUrls: ['./insta-posts.component.scss'],
})
export class InstaPostsComponent implements OnInit {
  instaPostData: InstaPost[] = [];
  instaSwiperConfig = {
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  };

  constructor(private instaPostServices: InstaPostServices) {}

  ngOnInit(): void {
    this.loadInstaPosts();
  }

  private loadInstaPosts(): void {
    this.instaPostServices
      .fetchData()
      .pipe(
        catchError((error: any) => {
          console.error('Error fetching Insta posts:', error);
          return of(null);
        })
      )
      .subscribe((res: any) => {
        if (res?.data && Array.isArray(res.data)) {
          this.instaPostData = res.data as InstaPost[];
          console.log(res.data);
        } else {
          console.warn('Invalid response format:', res);
        }
      });
  }
}
