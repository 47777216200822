import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { StoreResponse } from '../model/all-store.modal';
import { LocationResponse } from '../model/location.model';
import { StoreBannerResponse } from '../model/store-banner.model';
import { StoreCategoryResponse } from '../model/store-category.model';
import { IPAddressInfo } from '../model/ip-address.model';

@Injectable({
  providedIn: 'root',
})
export class StoreListService {
  constructor(private http: HttpClient) {}

  fetchStore(data?: any): Observable<StoreResponse> {
    return this.http.get<StoreResponse>(
      environment.base_url + environment.all_store,
      {
        params: data,
      }
    );
  }
  fetchStoreSearch(data?: any): Observable<StoreResponse> {
    return this.http.get<StoreResponse>(
      environment.base_url + environment.store_search,
      {
        params: data,
      }
    );
  }
  fetchPromotionalBanner(data?: any): Observable<StoreBannerResponse> {
    return this.http.get<StoreBannerResponse>(
      environment.base_url + environment.promotional_banners,
      {
        params: data,
      }
    );
  }

  fetchLocality(data?: any): Observable<LocationResponse> {
    return this.http.get<LocationResponse>(
      environment.base_url + environment.locality_fetch,
      {
        params: data,
      }
    );
  }

  fetchStoreCuisines(data?: any): Observable<any> {
    return this.http.get<any>(
      environment.base_url + environment.fetch_store_cuisines_method,
      {
        params: data,
      }
    );
  }
  fetch(data?: any): Observable<StoreCategoryResponse> {
    return this.http.get<StoreCategoryResponse>(
      environment.base_url + environment.fetch_store_category_method,
      {
        params: data,
      }
    );
  }
  onCreate(data): Observable<any> {
    return this.http.post(
      environment.base_url + environment.create_analytics,
      data
    );
  }
  getIPAddress(): Observable<IPAddressInfo> {
    return this.http.get<any>('https://ipapi.co/json/');
  }
}
