import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { VendorServices } from '../core/services/vendor.services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiService } from '../ui.service';
import {
  State,
  City,
  Area,
  StoreCategory,
} from '../core/model/vendor-onboarding.model';
import { FormService } from '../form.service';

@Component({
  selector: 'app-vendor-onboarding',
  templateUrl: './vendor-onboarding.page.html',
  styleUrls: ['./vendor-onboarding.page.scss'],
})
export class VendorOnboardingPage implements OnInit {
  vendorForm: FormGroup;
  _uiService = inject(UiService);

  types = ['VEG ONLY', 'NON VEG ONLY', 'BOTH'];
  serviceTypes = [
    'Dine-In Only',
    'Delivery Only',
    'Takeaway Only',
    'Dine-In + Delivery',
    'Dine-In + Takeaway',
    'Takeaway + Delivery',
    'ALL',
  ];

  cities$: Observable<any>;
  cities: City[] = [];
  states$: Observable<any>;
  states: State[] = [];
  areas$: Observable<any>;
  areas: Area[] = [];
  _formService = inject(FormService);
  storeCategory: StoreCategory[] = [];

  constructor(private vendorServices: VendorServices, private fb: FormBuilder) {
    this.vendorForm = this.fb.group(
      {
        vendorName: ['', Validators.required],
        vendorStoreName: ['', Validators.required],
        vendorStoreType: ['', Validators.required],
        vendorLocation: ['', Validators.required],
        userLoginId: [''],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
        type: ['', Validators.required],
        mobile: ['', [Validators.required, Validators.pattern('^\\d{10}$')]],
        alternateMobile: ['', Validators.pattern('^\\d{10}$')],
        email: ['', [Validators.email]],
        serviceType: [''],
        storeTiming: [''],
        StateIdFk: ['', Validators.required],
        CityIdFK: ['', Validators.required],
        AreaIdFk: [''],
      },
      { validators: this.passwordMatchValidator }
    );
    this.fetchStoreCategory();
  }

  ngOnInit() {
    this.fetchStates();
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;

    if (password !== confirmPassword) {
      formGroup.get('confirmPassword')?.setErrors({ passwordMismatch: true });
    } else {
      formGroup.get('confirmPassword')?.setErrors(null);
    }
  }

  fetchStoreCategory() {
    this.vendorServices.fetchCategory().subscribe((res) => {
      if (res) {
        this.storeCategory = res.data;
      }
    });
  }

  fetchStates() {
    this.states$ = this.vendorServices.fetchStates();
    this.states$.subscribe((res) => {
      this.states = res.data;
    });
  }

  fetchCities(stateId: number) {
    this.cities$ = this.vendorServices.fetchCity(stateId);
    this.cities$.subscribe((res) => {
      this.cities = res.data;
    });
  }

  fetchAreas(cityId: number) {
    this.areas$ = this.vendorServices.fetchArea(cityId);
    this.areas$.subscribe((res) => {
      this.areas = res.data;
    });
  }

  onChangeState(event: any) {
    if (event.detail.value) {
      this.fetchCities(+event.detail.value);
    }
  }

  onChangeCity(event: any) {
    if (event.detail.value) {
      this.fetchAreas(+event.detail.value);
    }
  }

  onSubmit() {
    this._formService.markFormAsTouched(this.vendorForm);
    const password = this.vendorForm.get('password')?.value;
    const confirmPassword = this.vendorForm.get('confirmPassword')?.value;

    if (password !== confirmPassword) {
      this._uiService.presentToast(
        'Confirm Password do not match'
      );
      return;
    }
    if (!this.vendorForm.valid) {
      this._uiService.presentAlert(
        'Error',
        '',
        'Please fil all the required fields!',
        'ios'
      );
      return;
    }

    const formData = new FormData();

    const mobile = this.vendorForm.get('mobile')?.value;
    if (mobile) {
      this.vendorForm.patchValue({ userLoginId: mobile });
    }

    Object.keys(this.vendorForm.controls).forEach((key) => {
      const value = this.vendorForm.get(key)?.value;
      if (key != 'confirmPassword') {
        if (value) {
          formData.append(key, value);
        }
      }
    });

    this.vendorServices.createVendor(formData).subscribe({
      next: (res) => {
        this._uiService.presentToast(
          'Thank you for registering with EaseInn! Our team will get back to you soon for further details and gudidance!'
        );
        this.vendorForm.reset();
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }
}
